<template>
  <vx-card style="padding-left: 10px">
    <vs-prompt
      title="Export as CSV"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
    </vs-prompt>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="makes"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <template slot="header">
          <vx-tooltip text="Export Make" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              icon="icon-download"
              color="danger"
              @click="getMakeForCSV"
               class="mx-3"
            >Export</vs-button
            >
          </vx-tooltip>
          <vx-tooltip text="Import vehicle data set from xls file">
            <vs-button ref="loadableButton"  id="button-to-import-loading"  class="mb-4 md:mb-0"
                       size="small"
                       icon-pack="feather"
                       icon="icon-download" color="warning" @click="loadImportForm">Import XLS</vs-button>
          </vx-tooltip>
          <input type="file" class="hidden" ref="select_vehicles_xls" @change="importVehicleFromXLS"
                 accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">

          <a :href='sampleVehicleDATASETXLS' download="carDataSet.xls">
            <vx-tooltip text="Download sample xls file to import vehicle data set">
              <vs-button  type="gradient" class="mx-1 md:mb-0" color="primary" size="small" icon="save_alt"></vs-button>
            </vx-tooltip>
          </a>
        </template>
<div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">



  <div class="flex flex-wrap-reverse items-center data-list-btn-container"></div>
  <v-select v-model="dataTableParams.limit" class="mr-4" :options="limitOptions" :clearable="false" />
</div>

<template slot="thead">
          <vs-th sort-key="make">Make</vs-th>
          <vs-th sort-key="family">Model</vs-th>
          <vs-th sort-key="style">BodyType</vs-th>
           <vs-th sort-key="drv">Drive</vs-th>
          <!-- <vs-th sort-key="variant">Badge</vs-th> -->
          <!-- <vs-th sort-key="engine">Engine</vs-th> -->
          <!-- <vs-th sort-key="cc">Cc</vs-th> -->
          <!-- <vs-th sort-key="size">Size</vs-th> -->
          <vs-th sort-key="transmission">Transmission</vs-th>
          <!-- <vs-th sort-key="cylinder">Cylinder</vs-th> -->
          <vs-th sort-key="year">Year</vs-th>
          <vs-th sort-key="secondaryFuelType">Fuel Type</vs-th>
          <vs-th sort-key="cylinderConfiguration">Cylinder Configuration</vs-th>

          <!-- <vs-th sort-key="createdAt">Created At</vs-th> -->
          <!-- <vs-th sort-key="updatedAt">Updated At</vs-th> -->
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

<template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].make">{{ data[indextr].make }}</vs-td>
            <vs-td :data="data[indextr].family">{{
              data[indextr].family
            }}</vs-td>
            <vs-td :data="data[indextr].style">{{ data[indextr].style }}</vs-td>
            <vs-td :data="data[indextr].drv">{{ data[indextr].drv }}</vs-td>
            <!-- <vs-td :data="data[indextr].engine">{{ data[indextr].engine }}</vs-td> -->
            <!-- <vs-td :data="data[indextr].cc">{{ data[indextr].cc }}</vs-td> -->
            <!-- <vs-td :data="data[indextr].size">{{ data[indextr].size }}</vs-td> -->
            <vs-td :data="data[indextr].transmission">{{
              data[indextr].autoMan
            }}</vs-td>
            <!-- <vs-td :data="data[indextr].cylinder">{{ data[indextr].cylinder }}</vs-td> -->
            <vs-td :data="data[indextr].year">{{ data[indextr].year }}</vs-td>
             <vs-td :data="data[indextr].secondaryFuelType">{{ data[indextr].secondaryFuelType }}</vs-td>
            <vs-td :data="data[indextr].cylinderConfiguration">{{
              data[indextr].cylinderConfiguration
            }}</vs-td>

            <!-- <vs-td
              :data="data[indextr].createdAt"
            >{{ moment(data[indextr].createdAt).format('DD/MM/YYYY') }}</vs-td>-->

            <!-- <vs-td
              :data="data[indextr].updatedAt"
            >{{ moment(data[indextr].updatedAt).format('DD/MM/YYYY') }}</vs-td>-->
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
                <!-- <vx-tooltip text="Edit" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="editDetailHandler(data[indextr]._id)"
                    color="warning"
                  ></vs-button>
                </vx-tooltip>-->
                <!--<vs-button type="border" size="small" icon-pack="feather" icon="icon-send" color="success" class="mr-2"></vs-button>
                -->
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-info"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <!-- <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="confirmDeleteRecord(data[indextr],indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>-->
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
</vs-table>
<span class="mr-2">{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import axios from "../../axios.js"


export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      makes: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedmake: {},

      fileName: "",
      // formats: ["xlsx", "csv", "txt"],
      cellAutoWidth: true,
      selectedFormat: "csv",
      vehicleXLSFile : null,
      sampleVehicleDATASETXLS: process.env.VUE_APP_BASE_API_URL+'/xls/carDataSet.xls',
      xlsErrorMsg:'Please select a valid XLS file of the specified format.',
      headerTitle: [
        "Id",
        "Make",
        "Model",
        "Body Type",
        "Drive",
        "Transmission",
        "Year",
        "Fuel Type",
        "Engine",
        "CC",
        "Size",
        "Cylinder",
        "CylinderConfiguration",
      ],
      headerVal: [
        "_id",
        "make",
        "family",
        "style",
        "drv",
        "autoMan",
        "secondaryFuelType",
        "year",
        "engine",
        "cc",
        "size",
        "cylinder",
        "cylinderConfiguration",
      ],
      selectedMakesForCsv: [],
      activePrompt: false,

    };
  },
  methods: {
    ...mapActions("make", ["fetchMakes",'importMakes']),
    ...mapActions(["initToFirstPage"]),
    moment(date) {
      return moment(date);
    },
    getMakeForCSV() {
      let self = this;
      let dataTableParams = {
        search: this.dataTableParams.search,
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: this.totalDocs,
      };
      this.fetchMakes(dataTableParams).then((res) => {
        self.selectedMakesForCsv = res.data.data.docs;
        this.activePrompt = true;
      });
    },
    getmakesList() {
      let self = this;
      this.fetchMakes(self.dataTableParams).then((res) => {
        self.makes = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getmakesList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getmakesList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getmakesList();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "MakeDetails", params: { id: id } });
    },
    loadImportForm(){
      this.vehicleXLSFile = null
      this.$refs.select_vehicles_xls.click()
    },

    confirmDeleteRecord(data, i) {
      this.selectedmake = data;
      this.selectedIndex = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${data.make}"`,
        accept: this.deleteMakeDetail,
        acceptText: "Delete",
      });
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = this.selectedMakesForCsv;
        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j];
        })
      );
    },
    clearFields() {
      this.fileName = "";
      this.cellAutoWidth = true;
      this.selectedFormat = "csv";
    },
    importVehicleFromXLS(e){
       this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: "#button-to-import-loading",
          scale: 0.45
        })
        
      e.preventDefault();
      let selectedFile = (e.target.files[0]) ? e.target.files[0] : null;

      if(selectedFile){

        let fileExt = selectedFile.name.split('.').pop();
        console.log(fileExt);
        // parseInt(selectedFile.size) > (1024 * 1024) || Check file size ??
        if ( fileExt == 'xls' || fileExt == 'xlsx' ) {
          const formData = new FormData();
          let config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          };

          formData.append('xls', selectedFile);
           this.importMakes(formData)
          // axios
          //   .post("admin/make-management/importVehicleDataSet", formData)
            .then(res => {
              this.$vs.loading.close("#button-to-import-loading > .con-vs-loading")
              console.log('\n Vehicle  import response ',res.data.data.errorRow)
              if (res.data && res.data.data.errorRow) {
                this.$vs.notify({
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                  title: 'Import Failed',
                  text: this.xlsErrorMsg})
              } else {
                this.$vs.loading.close("#button-to-import-loading > .con-vs-loading")
                this.$vs.notify({
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: 'success',
                  title: 'Import Success',
                  text: 'Vehicle Data Set imported successfully.'})
              }
              this.getmakesList()
            })
            .catch(err => {
              this.$vs.loading.close("#button-to-import-loading > .con-vs-loading")
              console.log('vehicle data set xls Import error : \n',err)
              this.$vs.notify({
                iconPack: "feather",
                icon: "icon-alert-circle",
                color:'danger',
                title:'Import Failed',
                text:'Error importing vehicle data set.'})
            });
        } else {
          this.$vs.loading.close("#button-to-import-loading > .con-vs-loading")
          this.$vs.notify({color:'warning',title:'Import Failed',text:this.xlsErrorMsg})
          return false;



        }
      } else {
        this.$vs.notify({
          iconPack: "feather",
          icon: "icon-alert-circle",
          color:'danger',
          title:'Import Failed',
          text:this.xlsErrorMsg})
        return
      }

    }

    // deleteMakeDetail() {
    //   this.deleteMake(this.selectedmake._id).then((res) => {
    //     this.$vs.notify({
    //       title: "Make Deleted",
    //       text: res.data.message,
    //       iconPack: "feather",
    //       icon: "icon-alert-circle",
    //       color: "success",
    //     });
    //     this.makes.splice(i, 1);
    //   });
    // },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getmakesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getmakesList();
      }
    },
  },
  created() {
    this.initToFirstPage({ pageType: "all"});
    this.getmakesList();
  },
};
</script>
